import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash';

import Layout from '../components/Layout'
import {HTMLContentParse} from "../components/Content";

export const IndexPageTemplate = ({
  articles,
  feedback
}) => (
  <div className="Content">
    { articles && _.map(articles, (article, index) => (
      <div className="Article" key={index}>
        <div className="featured-image" style={{
          backgroundImage: `url(${
            article.image && article.image.childImageSharp
              ? article.image.childImageSharp.fluid.src
              : article.image
            })`
        }}></div>
        <div className="entry-content">
          <h1 className="entry-title">{article.title}</h1>
          <div className="entry-lead">{article.subtitle}</div>
          <HTMLContentParse className="entry-body" content={article.body} />
        </div>
      </div>
    ))}
    <div className="Blockquote">
      <div className="Blockquote__entry">
        <h1 className="entry-title">Das sagen meine Kunden</h1>
        <div className="entry-lead">{feedback.subtitle}</div>
        <div className="blockquote">
          <HTMLContentParse className="Blockquote__entry__text" content={feedback.body} />
          <div className="Blockquote__entry__footer">&mdash;{feedback.author}</div>
        </div>
      </div>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  articles: PropTypes.array,
  feedback: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  console.log(data);
  return (
    <Layout>
      <IndexPageTemplate
        feedback={frontmatter.feedback}
        articles={frontmatter.articles}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        feedback {
          author
          body
          subtitle
        }
        articles {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          body
        }
      }
    }
  }
`
